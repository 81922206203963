
































  import '../scss/index.scss';
import Configuration from '../core/Configuration';
import { Vue, Component } from 'vue-property-decorator';
import { Button } from '@frontiers-vue/button';
import { Directives, IFieldValidatorModel } from '@frontiers-vue/common';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { ISubscribeData } from '../core/models';

@Component({
  components: { Button },
  directives: {
      'field-validator': new Directives.FieldValidator(),
  },
})
export default class Subscribe extends Vue {

  get showMessage(): boolean {
    return this.message.length > 0;
  }

  get messageClass(): string {
    return this.showError ? 'Messaging__error' : 'Messaging__success';
  }

  get formModel(): IFieldValidatorModel {
      return {
        formName: 'form',
        validate: this.validate,
        isValidForm: 'isValidForm',
      } as IFieldValidatorModel;
  }

  private validate: boolean = false;
  private isValidForm: boolean = true;
  private inputsDisabled: boolean = Configuration.Instance.LoggedIn;
  private buttonDisabled: boolean = false;
  private subscriptionTypeId: number = parseInt(Configuration.Instance.SubscriptionTypeId, 10);
  private subscriptionType: string = Configuration.Instance.SubscriptionType;
  private resourceId: string = Configuration.Instance.ResourceId;
  private resourceValue: string = Configuration.Instance.ResourceValue;
  private brandName: string = Configuration.Instance.BrandName;

  private email: string = '';
  private firstName: string = '';
  private lastName: string = '';

  private message: string = '';
  private showError: boolean = false;

  private validateForm(): void {
      this.message = '';
      this.validate = true;

      this.$nextTick(this.apiCall);
  }

  private apiCall(): void {
    if (this.isValidForm) {

      this.buttonDisabled = true;

      const data = {
        SubscriptionTypeId: this.subscriptionTypeId,
        ResourceId: this.resourceId,
        ResourceValue: this.resourceValue,
        Email: this.email,
        FirstName: this.firstName,
        LastName: this.lastName
      };

      axios
        .post(`/emails/api/v1/subscribeForm`,
          data,
        )
        .then((response: AxiosResponse<ISubscribeData>) => {
          if (response.data.confirmPending) {
            this.showError = false;
            this.message = 'Almost done! Please check your inbox to confirm your subscription.';
          }
          else {
            this.showError = false;
            this.message = 'You have been successfully subscribed!';
          }
        })
        .catch((error: AxiosError) => {
          this.showError = true;
          this.message = 'Something went wrong, please retry';

          this.buttonDisabled = false;
        });
    }
  }
}
